<template>
  <v-container>
    <PageTitleSearch
      :page-title="!$language ? 'Assignments' : 'অ্যাসাইনমেন্ট'"
      :placeholder="
        !$language
          ? 'Search By Assignment Title'
          : 'অ্যাসাইনমেন্টের শিরোনাম দিয়ে সার্চ করো'
      "
      @searchElenent="searchAssignment"
    ></PageTitleSearch>
    <v-container>
      <v-row>
        <v-col v-if="fetchingContent">
          <template>
            <div id="loading-wrapper">
              <div id="loading-text">LOADING</div>
              <div id="loading-content"></div>
            </div>
          </template>
        </v-col>
        <v-col v-else-if="noAssignmentCondition">
          <no-content-component
            :message="noAssignmentMessage"
          ></no-content-component>
        </v-col>
        <v-col
          v-else
          v-for="(assignment, i) in filterAssignments"
          :key="i"
          :cols="colsNum"
        >
          <m-assignment-list-item
            :assignment="assignment"
            @download="download(assignment.object_id)"
            @submit="showUploadDialog(assignment.object_id)"
          ></m-assignment-list-item>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="upload_dialog_visible" width="500px">
      <m-coursework-upload
        :saving="saving"
        :clearField="clearFile"
        @cancel="hideUploadDialog"
        @submit="uploadCoursework"
      ></m-coursework-upload>
    </v-dialog>
  </v-container>
</template>

<script>
import courseworkService from "#ef/assignment/services/CourseworkService";
import batchService from "#ef/batch/services/BatchService";
import PageTitleSearch from "@ef/global/components/PageTitleSearch.vue";
import MAssignmentListItem from "#ef/assignment/components/MAssignmentListItem";
import MCourseworkUpload from "#ef/assignment/components/MCourseworkUpload";
import { delayPromise } from "/global/utils/helpers";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "MAssignment",
  components: {
    MCourseworkUpload,
    MAssignmentListItem,
    PageTitleSearch,
    NoContentComponent,
  },
  props: {
    batch_id: String,
  },
  data() {
    return {
      assignments: [],
      upload_dialog_visible: false,
      saving: false,
      clearFile: false,
      submission_object_id: undefined,
      searchInput: "",
      fetchingContent: undefined,
    };
  },
  computed: {
    filterAssignments() {
      return this.assignments.filter((assignment) =>
        assignment.title.toLowerCase().match(this.searchInput.toLowerCase())
      );
    },
    assignmentRowCols() {
      return this.shareAssignmentPanel ? 8 : 9;
    },
    currentWidth() {
      return this.$vuetify.breakpoint.width;
    },
    colsNum() {
      return this.currentWidth < 800
        ? 12
        : this.currentWidth < 1280
        ? 12
        : this.currentWidth < 1920
        ? 6
        : 4;
    },
    noAssignmentCondition() {
      return this.assignments === undefined || this.assignments.length == 0;
    },
    noAssignmentMessage() {
      return !this.$language
        ? "You currently have no assignments"
        : "বর্তমানে আপনার কোনো এসাইনমেন্ট সমূহ নেই";
    },
  },
  created() {
    this.fetchCourseworks();
  },
  methods: {
    searchAssignment(searchInput) {
      this.searchInput = searchInput;
    },
    async fetchCourseworks() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      try {
        this.fetchingContent = true;
        this.assignments = await courseworkService.find(this.batch_id);
        for (const a of this.assignments) {
          const sub = await courseworkService.getSubmissions(a.object_id);
          this.$set(a, "submissions", sub);
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.fetchingContent = false;
      }
    },
    async fetchBatches() {
      try {
        this.batches = await batchService.find(this.batch_id);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async uploadCoursework(courseworkData) {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      try {
        this.saving = true;
        courseworkData.object_id = this.submission_object_id;
        await courseworkService.save(this.batch_id, courseworkData);

        await delayPromise(2000);
        await this.fetchCourseworks();
      } catch (e) {
        if (typeof e.message === "string") {
          let error_message = JSON.parse(e.message);
          if (error_message.error === "Unauthorized access") {
            this.$root.$emit("alert", [
              undefined,
              "The assignment you are trying to submit has already expired",
            ]);
          }
        } else {
          this.$root.$emit("alert", [undefined, e.message]);
        }
      } finally {
        this.saving = false;
        this.clearFile = true;
        this.hideUploadDialog();
      }
    },
    async download(coursework_id) {
      try {
        const url = await courseworkService.get(coursework_id, this.batch_id);
        window.open(url);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    hideUploadDialog() {
      this.upload_dialog_visible = false;
      this.clearFile = true;
    },
    showUploadDialog(object_id) {
      this.submission_object_id = object_id;
      this.upload_dialog_visible = true;
      this.clearFile = false;
    },
    shareAssignment(idx) {
      this.assignmentToShare = this.assignments[idx];
      this.shareAssignmentPanel = true;
    },
  },
};
</script>
