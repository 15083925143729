var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-end" },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-title",
        {
          staticClass: "justify-center",
          staticStyle: { "margin-top": "-20px" },
        },
        [
          _vm._v(
            _vm._s(!_vm.$language ? "Assessed Files" : "মূল্যায়নকৃত ফাইল")
          ),
        ]
      ),
      _c(
        "v-card-text",
        { staticClass: "pl-10 py-10" },
        [
          _vm._l(_vm.urls.url, function (url, index) {
            return _c(
              "v-row",
              { key: index },
              [
                _c("v-col", { staticClass: "pl-14" }, [
                  _vm._v(
                    _vm._s(index + 1) + ". " + _vm._s(_vm.getFileName(url))
                  ),
                ]),
                _c(
                  "v-col",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "px-12",
                        attrs: {
                          color: "primary",
                          depressed: "",
                          disabled: _vm.urls.assessed_url[index].length === 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("details", { index })
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("visibility"),
                        ]),
                        _vm._v(_vm._s(!_vm.$language ? "Preview" : "দেখো")),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c("div", { staticStyle: { height: "30px" } }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }