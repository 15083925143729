var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mk-card-radius pa-4" },
    [
      _c("v-card-title", { staticClass: "title mk-highlight-text" }, [
        _vm._v(
          _vm._s(!_vm.$language ? "Submit Your Work" : "আপনার ফাইল সাবমিট করুন")
        ),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              ref: "assignment",
              model: {
                value: _vm.isValid,
                callback: function ($$v) {
                  _vm.isValid = $$v
                },
                expression: "isValid",
              },
            },
            [
              _c("v-file-input", {
                attrs: {
                  chips: "",
                  multiple: "",
                  label: !_vm.$language
                    ? "Upload your files here"
                    : "ফাইল আপলোড  করো",
                  rules: _vm.rules,
                },
                nativeOn: {
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                  },
                },
                model: {
                  value: _vm.formData.files,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "files", $$v)
                  },
                  expression: "formData.files",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", text: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [
              _vm._v(
                " " + _vm._s(!_vm.$language ? "Cancel" : "বাতিল করো") + " "
              ),
            ]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", loading: _vm.saving },
              on: { click: _vm.save },
            },
            [
              _vm._v(
                " " + _vm._s(!_vm.$language ? "Upload" : "আপলোড করো") + " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }