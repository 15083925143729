<template>
  <m-base-card-new>
    <template v-slot:header>
      <MBaseCardHeaderNew
        :title="assignment.title"
        :available-date="
          !$language
            ? 'Available Date: ' + 'N/A'
            : 'অ্যাসাইনমেন্ট প্রকাশের তারিখ: ' + 'N/A'
        "
        :btn-name="!$language ? 'Assessment' : 'মূল্যায়ন করো'"
        :element_type="'assignment'"
        @header_element_3="seeAssessed"
      ></MBaseCardHeaderNew>
      <v-dialog v-model="previewAssess" width="70%">
        <m-assessment-list
          :urls="assessUrls"
          @close="previewAssess = false"
          @details="
            $router.push({
              name: 'assessment',
              params: {
                object_id: assignment.object_id,
                index: $event.index.toString(),
                type: 'assignment',
              },
            })
          "
        ></m-assessment-list>
      </v-dialog>
    </template>
    <template v-slot:middle-content>
      <MBaseCardMiddleContent
        :element_1="assignment.due_date"
        :element_2="submitted ? 'Submitted' : 'Not Submitted'"
        :element_3="assignment.total_score"
        :element_4="grade.toString() || 'Not Published'"
        :element_type="'assignment'"
      ></MBaseCardMiddleContent>
    </template>
    <template v-slot:footer>
      <MBaseCardFooter
        :element_1="!$language ? 'Details' : 'বিস্তারিত'"
        :element_2="!$language ? 'Submit' : 'সাবমিট করো'"
        @footer_element_1="
          fetchURL();
          preview = true;
        "
        @footer_element_2="$emit('submit')"
      ></MBaseCardFooter>
      <v-dialog v-model="preview">
        <file-previewer
          v-if="preview"
          :preview-url="previewUrl"
          :download-url="downloadUrl"
          :title="assignment.title"
          :is-video="assignment.is_video"
          :is-download="assignment.is_downloadable"
          @cancel="preview = false"
        ></file-previewer>
      </v-dialog>
    </template>
  </m-base-card-new>
</template>

<script>
import _ from "lodash";
import courseworkService from "#ef/assignment/services/CourseworkService";
import MBaseCardNew from "~ef/components/MBaseCardNew.vue";
import MBaseCardHeaderNew from "~ef/components/MBaseCardHeaderNew.vue";
import MBaseCardMiddleContent from "~ef/components/MBaseCardMiddleContent.vue";
import MBaseCardFooter from "~ef/components/MBaseCardFooter.vue";
import MAssessmentList from "#ef/assignment/components/MAssessmentList";
import FilePreviewer from "~ef/components/FilePreviewer";

export default {
  name: "MAssignmentListItem",
  components: {
    MBaseCardNew,
    MBaseCardHeaderNew,
    MBaseCardMiddleContent,
    MBaseCardFooter,
    FilePreviewer,
    MAssessmentList,
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      preview: false,
      previewUrl: undefined,
      downloadUrl: undefined,
      previewAssess: false,
      assessUrls: [],
    };
  },
  computed: {
    dueDateOver() {
      const now = new Date();
      const date = now.toISOString().split("T")[0];

      return date > this.assignment.due_date;
    },
    submitted() {
      return (
        this.assignment.submissions && this.assignment.submissions.length > 0
      );
    },
    upload_date() {
      if (this.submitted) {
        const last_submission = _.sortBy(this.assignment.submissions, [
          "upload_date",
        ]);
        return last_submission[last_submission.length - 1].upload_date;
      }
      return "Not Available";
    },
    grade() {
      if (this.submitted) {
        let grade = this.assignment.submissions[0].grade;
        return grade !== undefined ? grade : "Not published";
      }
      return "Not Submitted";
    },
  },
  methods: {
    async fetchURL() {
      try {
        let a = await courseworkService.get(
          this.assignment.object_id,
          this.$ielts_batch_id,
        );
        this.previewUrl = a.preview_url;
        this.downloadUrl = a.download_url;
      } catch (e) {
        this.preview = false;
      }
    },
    async seeAssessed() {
      try {
        let submissions = await courseworkService.getSubmissions(
          this.assignment.object_id,
        );
        if (submissions.length) {
          this.assessUrls = submissions[0];
          this.previewAssess = true;
        } else {
          this.$root.$emit("alert", [
            undefined,
            !this.$language
              ? "You haven't submitted yet"
              : "আপনি এখনও জমা দেননি",
          ]);
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
  },
};
</script>

<style lang="scss">
.m-assignment-list-item {
  font-family: Poppins, sans-serif !important;
  color: #3d3d3d !important;

  .v-card__text {
    color: #3d3d3d !important;
  }

  button {
    font-family: Poppins, sans-serif !important;
  }
}
</style>
