<template>
  <v-card>
    <div class="d-flex justify-end">
      <v-btn icon @click="$emit('close')"><v-icon>close</v-icon></v-btn>
    </div>
    <v-card-title class="justify-center" style="margin-top: -20px;">{{
      !$language ? "Assessed Files" : "মূল্যায়নকৃত ফাইল"
    }}</v-card-title>
    <v-card-text class="pl-10 py-10">
      <v-row v-for="(url, index) in urls.url" :key="index">
        <v-col class="pl-14">{{ index + 1 }}. {{ getFileName(url) }}</v-col>
        <v-col
          ><v-btn
            color="primary"
            class="px-12"
            depressed
            :disabled="urls.assessed_url[index].length === 0"
            @click="$emit('details', { index })"
            ><v-icon left>visibility</v-icon
            >{{ !$language ? "Preview" : "দেখো" }}</v-btn
          ></v-col
        >
      </v-row>
      <div style="height: 30px;"></div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "MAssessmentList",
  props: {
    urls: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getFileName(url) {
      return decodeURIComponent(
        url
          .split("?")[0]
          .split("/")
          .pop(),
      );
    },
  },
};
</script>

<style></style>
