var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("m-base-card-new", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("MBaseCardHeaderNew", {
              attrs: {
                title: _vm.assignment.title,
                "available-date": !_vm.$language
                  ? "Available Date: " + "N/A"
                  : "অ্যাসাইনমেন্ট প্রকাশের তারিখ: " + "N/A",
                "btn-name": !_vm.$language ? "Assessment" : "মূল্যায়ন করো",
                element_type: "assignment",
              },
              on: { header_element_3: _vm.seeAssessed },
            }),
            _c(
              "v-dialog",
              {
                attrs: { width: "70%" },
                model: {
                  value: _vm.previewAssess,
                  callback: function ($$v) {
                    _vm.previewAssess = $$v
                  },
                  expression: "previewAssess",
                },
              },
              [
                _c("m-assessment-list", {
                  attrs: { urls: _vm.assessUrls },
                  on: {
                    close: function ($event) {
                      _vm.previewAssess = false
                    },
                    details: function ($event) {
                      _vm.$router.push({
                        name: "assessment",
                        params: {
                          object_id: _vm.assignment.object_id,
                          index: $event.index.toString(),
                          type: "assignment",
                        },
                      })
                    },
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "middle-content",
        fn: function () {
          return [
            _c("MBaseCardMiddleContent", {
              attrs: {
                element_1: _vm.assignment.due_date,
                element_2: _vm.submitted ? "Submitted" : "Not Submitted",
                element_3: _vm.assignment.total_score,
                element_4: _vm.grade.toString() || "Not Published",
                element_type: "assignment",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("MBaseCardFooter", {
              attrs: {
                element_1: !_vm.$language ? "Details" : "বিস্তারিত",
                element_2: !_vm.$language ? "Submit" : "সাবমিট করো",
              },
              on: {
                footer_element_1: function ($event) {
                  _vm.fetchURL()
                  _vm.preview = true
                },
                footer_element_2: function ($event) {
                  return _vm.$emit("submit")
                },
              },
            }),
            _c(
              "v-dialog",
              {
                model: {
                  value: _vm.preview,
                  callback: function ($$v) {
                    _vm.preview = $$v
                  },
                  expression: "preview",
                },
              },
              [
                _vm.preview
                  ? _c("file-previewer", {
                      attrs: {
                        "preview-url": _vm.previewUrl,
                        "download-url": _vm.downloadUrl,
                        title: _vm.assignment.title,
                        "is-video": _vm.assignment.is_video,
                        "is-download": _vm.assignment.is_downloadable,
                      },
                      on: {
                        cancel: function ($event) {
                          _vm.preview = false
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }